let resumeData = {
    "name": "Artjan Illi",
    "role": "Frontend und Backend Entwickler",
    "linkedinId":"Artjan Illi",
    "skypeid": "",
    "roleDescription": "Ich beschäftige mich gerne mit verschiedenen Bereichen der Frontend-Entwicklung und lerne in meiner Freizeit gerne neue Technologien kennen",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"linkedin.com/in/artjan-illi-20a943116",
          "className":"fa fa-linkedin"
        },
       /* {
          "name":"github",
          "url":"http://github.com/rbhatia46",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"http://twitter.com/rbhatia46",
          "className":"fa fa-twitter"
        }*/
      ],
    "aboutme":"Ich bin eine Person welche neu erlerntes schnell Aufnimmt und umsetzten kann. In Stresssituation behalte ich den Überblick und ein ruhiges Gemüt. Ich bin zuverlässig und pünktlich. Auch mit Kritik kann ich gut umgehen und versuche die Kritik bestmöglich umzusetzen. Durch meine Ausbildung ist eine planmässige und strukturierte Arbeitsweise jederzeit von mir zu erwarten. Durch meine Lernbereitschaft ist es natürlich für mich das ich mich stetig weiterbilde und immer auf dem neusten Stand bin oder auch wenn gewünscht etwas neues sofort Lerne.",
    "address":"Schweiz",
    "website":"https://ailli.ch",
    "education":[
      {
        "UniversityName":"Höhere Fachschule IBZ",
        "specialization":"Eid. Diplomierter Techniker Informatiker",
        "MonthOfPassing":"Nov",
        "YearOfPassing":"2022",
        "Achievements":"Vertiefte Kenntnisse in Programmierung"
        +"\nVertiefte Kenntnisse in Webprogrammierung"
        +"\nVertiefte Kenntnisse in Datenbankprogrammierung und Verwaltung"
        +"\nGrundlagen Betriebssystem"
        +"\nGrundlagen Systemtechnik"
        +"\nProzess Management"
        +"\nFührung eines IT Teams"
      },
      {
        "UniversityName":"ZLi Erwachsenenbildung",
        "specialization":"EFZ Informatiker Richtung Applikationsentwicklung",
        "MonthOfPassing":"Juni",
        "YearOfPassing":"2015",
        "Achievements":" Grundlagen der Informatik"
        +"\nGrundlagen von Webentwicklung"
        +"\nGrundlagen Programmierung"
        +"\nGrundlagen Systemtechnik"
        
      }
    ],
    "work":[
      {
        "CompanyName":"WEMF AG",
        "specialization":"Front/Backendentwickler",
        "MonthOfLeaving":"",
        "YearOfLeaving":"",
        "Achievements":"Weiterentwicklung der Firmenwebseite nach Projektleiter Anforderungen"
        +"\nEntwicklung von Layouts"
        +"\nRechnungslayout Drag And Drop Entwicklung"
        +"\nProgrammierung von Error Handling"

      },
      {
        "CompanyName":"Assepro",
        "specialization":"Front/Backendentwickler",
        "MonthOfLeaving":"Mai",
        "YearOfLeaving":"2022",
        "Achievements":"Weiterentwicklung der Firmenwebseite nach Projektleiter Anforderungen"
        +"\nEntwicklung von komplexen Berechnungen"
        +"\nWeiterentwicklung Backends"
        +"\nProgrammierung von Error Handling"

      },
      {
        "CompanyName":"umana-ehealth",
        "specialization":"Front/Backendentwickler",
        "MonthOfLeaving":"März",
        "YearOfLeaving":"2021",
        "Achievements":"Entwicklung eines Email Programms"
        +"\nWeiterentwicklung Frontends"
        +"\nWeiterentwicklung Backends"
        +"\nIntegration Vue.js"
        +"\nIntegration Vue.js"
        +"\Entwicklung eines Email Programms"

      },
      {
        "CompanyName":"EOSCOP",
        "specialization":"Front/Backendentwickler",
        "MonthOfLeaving":"Oktober",
        "YearOfLeaving":"2020",
        "Achievements":"Entwicklung eines Lokalisierungssystem"
        +"\nWeiterentwicklung der SQL-Datenbank"
      },
      {
        "CompanyName":"OF-Software",
        "specialization":"Front/Backendentwickler",
        "MonthOfLeaving":"Mai",
        "YearOfLeaving":"2020",
        "Achievements":"Entwicklung verschidener Frontends"
        +"\nERP-System Weiterentwicklung"
        +"\nEntwicklung App für IOS, Android, Windows"
        +"\nWeiterentwicklung Datenbank"
      },
      {
        "CompanyName":"spar-mit.com",
        "specialization":"Front/Backendentwickler",
        "MonthOfLeaving":"Juni",
        "YearOfLeaving":"2018",
        "Achievements":"Entwicklung einer Suchmaschine"
        +"\nAnpassung des Newsletters"
        +"\nIntegrieren des Web-Designs"
        +"\nEntwickeln und Einbinden eines Buchungsformulars"
      },
      {
        "CompanyName":"creants.com",
        "specialization":"Webentwickler",
        "MonthOfLeaving":"Mai",
        "YearOfLeaving":"2017",
        "Achievements":"Umsetzen vom Webseiten nach Kundenspezifikation"
        +"\nProgrammierung mit Coldfusion"
        +"\nEinrichten und Wartung von Datenbanken"
        +"\nDesignen und programmieren von Datenbanken"
        +"\nEinrichtung und Wartung von Netzwerkumgebungen"
      },
      {
        "CompanyName":"Interbrain",
        "specialization":"Praktikum als Applikationsentwickler",
        "MonthOfLeaving":"Juli",
        "YearOfLeaving":"2015",
        "Achievements":"Installieren von Arbeitsumgebungen"
        +"\nProgrammierung in C#"
        +"\nWPF Anwendungen umsetzen"
        +"\nDesignen und programmieren von Datenbanken"
        +"\nProgrammierung in OOP und MVC"
      }
    ],
    "skillsDescription":"Meine Skills",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"MSSQL"
      },
      {
        "skillname":"PHP"
      },
      /*{
        "skillname":"C-Sharp"
      }*/
    ],
    "portfolio":[
      {
        "name":"project1",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      },
      {
        "name":"project2",
        "description":"mobileapp",
        "imgurl":"images/portfolio/project.jpg"
      },
      {
        "name":"project3",
        "description":"mobileapp",  
        "imgurl":"images/portfolio/project2.png"
      },
      {
        "name":"project4",
        "description":"mobileapp",
        "imgurl":"images/portfolio/phone.jpg"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData